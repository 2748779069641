import { connect } from "react-redux";
import React, { Component } from "react";

import { fetchTagSuggestions } from "../../actions";

import { Tags } from "./tags";

/**
 * Tags component that is connected to the store.
 */
class ConnectedTags extends Component {
  onTermChange = (value) => {
    value && this.props.dispatch(fetchTagSuggestions(value));
  };

  render() {
    return <Tags {...this.props} onTermChange={this.onTermChange} />;
  }
}

const mapStateToProps = (state) => ({
  suggestions: state.tags.suggestions,
});

export default connect(mapStateToProps)(ConnectedTags);
