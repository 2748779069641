import React, { Component } from "react";
import clsx from "clsx";

import Checkbox from "@deprecated/material-ui/Checkbox";
import PropTypes from "prop-types";

/**
 * Custom button themed as material-ui flat
 */
class CheckboxGroup extends Component {
  /*
    Method used for handling string of comma-separated checkbox-group values
    Example: sde_verification_filter default="none,partial,pass,fail"
  */
  getValue() {
    const { commaSeparated, value } = this.props;

    if (commaSeparated) {
      return value.split(",").filter((x) => x);
    }
    return value;
  }

  handleOnCheck(option, e, checked) {
    const { commaSeparated, onChange } = this.props;
    const value = this.getValue();

    let newValue;

    if (checked && !value.includes(option)) {
      newValue = [...value, option];
    }

    if (!checked) {
      newValue = value.filter((item) => item !== option);
    }

    if (commaSeparated) {
      newValue = newValue.join(",");
    }

    onChange(newValue);
  }

  render() {
    const { checkboxProps, className, errorText, name, options, style } =
      this.props;

    const value = this.getValue();

    const checkboxes = options.map((option) => {
      const checked = value.includes(option.value);
      const classNames = clsx({
        [className || ""]: true,
        "sde-survey-question-answer": true,
        "sde-survey-question-answer-dirty": option.dirty,
      });

      return (
        <div
          className={classNames}
          key={option.value}
          role="menuitemcheckbox"
          aria-checked={checked}
        >
          <Checkbox
            label={option.label}
            value={option.value}
            data-cy={`checkbox-${option.value}`}
            checked={checked}
            disabled={option.disabled}
            name={name}
            onCheck={this.handleOnCheck.bind(this, option.value)}
            {...checkboxProps}
          />
        </div>
      );
    });

    return (
      <div className="sde-checkbox-group" style={style} role="group">
        {checkboxes}
        {errorText && <div style={{ color: "#f44336" }}>{errorText}</div>}
      </div>
    );
  }
}

CheckboxGroup.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
        .isRequired,
      disabled: PropTypes.bool,
    })
  ),
};

CheckboxGroup.defaultProps = {
  className: "sde-checkbox-group-checkbox",
  checkboxProps: {},
  options: [],
};

export default CheckboxGroup;
