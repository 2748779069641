import React, { useEffect, useState } from "react";
import moment from "moment";

import ClearIcon from "@deprecated/material-ui/svg-icons/navigation/cancel";
import MaterialDatePicker from "@deprecated/material-ui/DatePicker";
import { DatePickerProps as MaterialDatePickerProps } from "@deprecated/material-ui";

import IconButton from "../buttons/icon";
import { getTextFieldStyleProps } from "../TextField/utils";

type DateValue = string | null;

interface CustomDatePickerProps {
  clearable?: boolean;
  maxDate?: string;
  minDate?: string;
  onChange?(value: DateValue): void;
  value?: DateValue;
}

type DatePickerProps = Omit<
  MaterialDatePickerProps,
  keyof CustomDatePickerProps
> &
  CustomDatePickerProps;

const mergePropsWithDefaults = (props: DatePickerProps) => ({
  ...props,
  ...getTextFieldStyleProps(props),
  textFieldStyle: {
    width: "100%",
    ...props.textFieldStyle,
  },
});

const stringToDate = (dateString?: DateValue): Date | undefined => {
  // Converts a string to a date
  if (!dateString) {
    return undefined;
  }
  const date = moment(dateString, "YYYY-MM-DD");
  return date.isValid() ? date.toDate() : undefined;
};

const dateToString = (date?: Date): DateValue =>
  // Converts a date to a string
  date ? moment(date).format("YYYY-MM-DD") : null;
/**
 * Lets you pick a date. Amazing!
 */
const DatePicker = ({
  clearable,
  onChange,
  maxDate,
  minDate,
  value: initialValue,
  ...props
}: DatePickerProps) => {
  const [value, setValue] = useState<DateValue | undefined>(null);

  useEffect(() => {
    setValue(initialValue);
  }, []);

  const buttonStyle = {
    color: null,
    fontFamily: null,
    fontSize: null,
    backgroundColor: "transparent",
    minWidth: "64px",
  };

  // Props that we added to material-ui,
  // but are not in the type definition
  const customProps = {
    okClassName: "sde-button sde-button-primary",
    okStyle: buttonStyle,
    cancelClassName: "sde-button",
    cancelStyle: buttonStyle,
  };

  const clear = () => {
    setValue(null);
    onChange && onChange("");
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOnChange = (e: any, value: Date) => {
    const newValue = dateToString(value);
    setValue(newValue);
    onChange && onChange(newValue);
  };

  return (
    <div className="sde-date-picker">
      <MaterialDatePicker
        {...mergePropsWithDefaults(props)}
        maxDate={stringToDate(maxDate)}
        minDate={stringToDate(minDate)}
        value={stringToDate(value)}
        onChange={handleOnChange}
        {...customProps}
      />
      {clearable && value && (
        <IconButton
          className="sde-date-picker-clear"
          style={{
            width: null,
            height: null,
            opacity: props.disabled ? 0.38 : 0.87,
            position: null,
          }}
          onClick={clear}
          disabled={props.disabled}
        >
          <ClearIcon />
        </IconButton>
      )}
    </div>
  );
};

DatePicker.defaultProps = {
  autoOk: true,
  clearable: true,
  mode: "landscape",
};

export default DatePicker;
