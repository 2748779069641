import React from "react";

import clsx from "clsx";
import { gettext } from "django-i18n";

/**
 * A fabulously animated horizontal bar.
 */
const Loader = (props) => (
  <div
    className={clsx("sde-loading-bar", props.className)}
    data-cy="loading-bar-component"
    aria-label={gettext("Loading Bar")}
    style={{
      display: (props.loading && "block") || "none",
      ...props.style,
    }}
  />
);

export default Loader;
